
import { CoreBaseService } from '../Core/Services/Base.service'; 
import { UserModel } from '../models/UserModel';


export class UserService extends CoreBaseService {

  constructor() {
      super(UserModel);
  }

  login(data) {
    return this.sub('user/login').create(data);
  }

  logout() {
    return this.sub('user/logout').get();
  }

}