import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Form, FormInput, FormTextarea, FormFeedback, Button } from "shards-react";
import { CoreServiceErrorModel } from "../../Core/Models/ServiceError.model";
import ReactQuill from "react-quill";
import '../../../node_modules/react-quill/dist/quill.snow.css';


function TextEditorComponent(props) {

  const cData = {
    defaultData: props.defaultData,
    errors: props.errors ||  new CoreServiceErrorModel(),
    save: props.onSave,
    onDelete: props.onDelete
  }
  
  const [ formData, setFormData ] = useState(props.defaultData);


  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    props.getData(formData);
  }, [formData]);

  const titleChange = (event) => {
    formData.title = event.target.value;
    setFormData({...formData});
  }

  const longTitleChange = (event) => {
    formData.longTitle = event.target.value;
    setFormData({...formData});
  }

  const contentChange = (value) => {
    formData.content = value;
    setFormData({...formData});
  }

  const savePressed = () => {
    if(cData.save) {
      cData.save();
    }
  }

  return (
    <Card small className="mb-3">
      {
        cData.errors ? 
          <CardBody>
            <code className="pl-0 d-block mb-2">Private titles: ['bio short', 'bio']</code>
            <Form className="add-new-post">
              <FormInput 
                invalid={cData.errors.hasError('title')} 
                defaultValue={cData.defaultData.title} 
                onChange={(e) => titleChange(e)} 
                size="lg" 
                placeholder="Your text title" 
              />

              { cData.errors.hasError('title') ? <FormFeedback className="mb-3">{ cData.errors.hasError('title').message }</FormFeedback> : null }

              <FormInput 
                invalid={cData.errors.hasError('longTitle')} 
                defaultValue={cData.defaultData.longTitle} 
                onChange={(e) => longTitleChange(e)} 
                size="lg" 
                className="mt-1"
                placeholder="Your text long title" 
              />

              { cData.errors.hasError('longTitle') ? <FormFeedback className="mb-3">{ cData.errors.hasError('longTitle').message }</FormFeedback> : null }

            <ReactQuill
              onChange={contentChange}
              value={formData ? formData.content ? formData.content : '' : ''}
              className="mt-3"
              modules={{
                toolbar: {
                  container: [
                    [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                    ['code-block']
                  ]
                }
              }}
            />

            { cData.errors.hasError('content') ? <FormFeedback className="mb-3">{ cData.errors.hasError('content').message }</FormFeedback> : null }

            </Form>
            {
              cData.save ? 
                <div className="float-right">
                  <Button onClick={() => savePressed()} theme="accent" size="sm" className="mt-3">
                      <i className="material-icons">file_copy</i> Save
                  </Button>
                </div>
              : null
            }
            {/*
              cData.onDelete ? 
                <div className="float-left">
                  <Button onClick={() => deletePressed()} size="sm" className="mt-3 btn btn-danger">
                      <i className="material-icons">file_copy</i> Delete
                  </Button>
                </div>
              : null
            */}
          </CardBody>
        : null
      }
    </Card>
  );
}

export default TextEditorComponent;
