import { CoreBaseModel } from '../Core/Models/Base.model';
import { Environment } from '../Setup'

export class ResourcesModel extends CoreBaseModel {

    static getResourceName() {
        return 'resources';
    }

    getDateString(what = 'createdAt') {
        return new Date(this[what]).toDateString();
    }

    getFilePath() {
        return `${ Environment.ENDPOINT }/documents/resources/${this.name}`;
    }
}