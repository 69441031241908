
import { CoreBaseService } from '../Core/Services/Base.service'; 
import { PostModel } from '../models/PostModel';
import { Environment } from '../Setup'; 

export class PostsService extends CoreBaseService {

  constructor() {
      super(PostModel);
  }

  getPost(id = null) {
    return this.get(id);
  }

  static previewUrl(slug = null) {
    return `${Environment.ENDPOINT}blog/post/${slug}?status=preview`;
  }

}