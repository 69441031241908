import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormInput } from "shards-react";
import { FormTextarea, Button } from "shards-react";
import { useToasts } from 'react-toast-notifications';
import { CalculatorService } from "./../../../services/branches/CalculatorService";
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; 


function CalculatorBranch(props) {
  
  const [ errors, setErrors ] = useState(null); 
  const { addToast } = useToasts();
  const [ operationStatus, setOperationStats ] = useState(null); 
  const [ thought, setThought ] = useState(''); 
  const [ totalVerses, setTotalVerses ] = useState(null); 
  const [ verseToModify, setVerseToModify ] = useState(null); 
  const [ loading, setLoading ] = useState(true);
  const [ deleted, setDeleted ] = useState(0);
  const [ added, setAdded ] = useState(0);
  const [ perPage, setPerPage ] = useState(50);
  const [ verses, setVerses ] = useState([]);
  const calculatorService = new CalculatorService();
  let patchTimeout = null;
    
  useEffect(() => {
    setLoading(true);
    calculatorService.get('', {status: 1, perPage, page: 0}).then((res) => {
      setVerses(res.getData());
      setLoading(false);
    });
  }, [deleted, added, perPage]);

  const addThought = () => {
    setErrors(null);
    if(thought) {
      calculatorService.addThought({thought}).then(res => {
        if(res.hasErrors()) {
          addToast('An error has occurred', { appearance: 'error' });
          setErrors(res.getErrors());
          setOperationStats('error');
        } else {
          addToast('Saved successfully', { appearance: 'success' });
          setOperationStats('success');
          setTotalVerses(res.getData().totalVerses);
          setThought(null);
          setAdded(added + 1);
        }
      }).catch(er => {
        addToast('An error has occurred', { appearance: 'error' });
        setErrors(er);
        setOperationStats('error');
      })
    }
  }

  const writeThought = (event) => {
    setThought(event.target.value);
  }

  const newThought = () => {
    setOperationStats(null);
  }

  const modifyVerse = (id) => {
    setVerseToModify(id);
  }

  const saveModifiedVerse = (verse, id) => {
    if(patchTimeout) {
      clearTimeout(patchTimeout);
    }
    if(verse) {
      patchTimeout = setTimeout(() => {
        calculatorService.update({verse}, id).then(res => {
        if(res.hasErrors()) {
          addToast('An error has occurred', { appearance: 'error' });
          setErrors(res.getErrors());
          setOperationStats('error');
        } else {
          addToast('Saved successfully', { appearance: 'success' });
          setOperationStats('success');
        }
      }).catch(er => {
        addToast('An error has occurred', { appearance: 'error' });
        setErrors(er);
        setOperationStats('error');
      })
      }, 1000);
    }
  }

  const deleteVerse = (id) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'The thought will be deleted permanently',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            calculatorService.delete(id).then(res => {
              if(res.hasErrors()) {
                addToast('An error has occurred', { appearance: 'error' });
                setErrors(res.getErrors());
                setOperationStats('error');
              } else {
                addToast('Removed successfully', { appearance: 'success' });
                setOperationStats('success');
                setDeleted(deleted + 1);
              }
            });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const search = (searchString) => {
    setLoading(true);
    calculatorService.get('', {status: 1, perPage: 50, page: 0, searchString}).then((res) => {
      setVerses(res.getData());
      setLoading(false);
    });
  }

  const changePerPage = (value) => {
    setPerPage(value);
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row>
        {/* Editor */}
        <Col lg="12" md="12">
          <h3>Calculator</h3>
          <h6>Add thought</h6>
          {
            operationStatus
            ? 
            <p>
              { totalVerses
                ? <p>Calculator updated: there are { totalVerses } thoughts now</p>
                : null
              }
              <Button onClick={() => newThought()} size="sm" className="mt-3 btn btn-success">
                <i className="material-icons">add_circle</i> Add new thougth
              </Button>
            </p>
            : 
            <div>
              <FormTextarea
                size="lg" 
                defaultValue={thought} 
                onChange={(e) => writeThought(e)}
                className="mt-1" 
                placeholder="Your thought" 
              />
              <Button disabled={!thought} onClick={() => addThought()} size="sm" className="mt-3 btn btn-success">
                <i className="material-icons">add_circle</i> Add
              </Button>
            </div>
          }
          </Col>
          </Row>
          <div className="mt-5">
            <h5>Verses</h5>
            <Row className="mb-2">
              <Col lg="6" sm="12">
                <FormInput
                  size="lg" 
                  defaultValue={null} 
                  onChange={(e) => search(e.target.value)}
                  className="mt-1 mb-1"
                  placeholder="Filter"
                ></FormInput>
              </Col>
              <Col lg="6" sm="12">
                <FormInput
                  size="lg" 
                  defaultValue={perPage} 
                  onChange={(e) => changePerPage(e.target.value)}
                  className="mt-1 mb-1"
                  placeholder="Per page"
                  type="number"
                ></FormInput>
              </Col>
            </Row>
            {
              loading ? 
                <div class="text-center">
                  <div class="spinner-grow text-primary" style={{width: '3rem', height: '3rem'}} role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              : verses.length ?

              
                verses.map(verse => {
                  return ( 
                    <Row className="verse-row" key={verse._id}>
                      <Col lg="4" sm="12" onClick={() => { modifyVerse(verse._id) }}>
                        { verseToModify != verse._id ?
                          <div>{ verse.verse }</div>
                          : <FormTextarea
                            size="lg" 
                            defaultValue={verse.verse} 
                            onChange={(e) => saveModifiedVerse(e.target.value, verse._id)}
                            className="mt-1"
                          />
                        }
                      </Col>
                      <Col  lg="2" sm="12">
                        { verse.status === 1 ? 'Published' : 'Draft' }
                      </Col>
                      <Col  lg="2" sm="12">
                        Shown: { verse.timesShown } time{ verse.timesShown > 1 ? 's' : '' }
                      </Col>
                      <Col  lg="2" sm="12">
                        { verse.getDateString() }
                      </Col>
                      <Col  lg="2" sm="12">
                        <Button onClick={() => { deleteVerse(verse._id) }} className="btn btn-danger">Delete</Button>
                      </Col>
                    </Row>
                  )
                })
              : <div className="alert alert-warning">No data</div>
            }
          </div>
    </Container>
  )
};

export default CalculatorBranch;
