import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Button, InputGroup, InputGroupAddon, FormCheckbox, FormInput } from "shards-react";
import { CategoryService } from "../../services/CategoryService";

function SidebarCategories(props) {

  const cData = {
    preselectedIds: props.preselectedCategories,
    getCategories: props.getCategories
  }

  const categoryService = new CategoryService();
  const [ categories, setCategories ] = useState([]);
  const [ newCategory, setNewCategory ] = useState('');
  const [ selectedIds, setsSelectedIds ] = useState({});

  useEffect(() => {
    categoryService.get().then((res) => {
      setCategories(res.getData());
      if(cData.preselectedIds) {
        cData.preselectedIds.forEach(id => {
          selectCategory(null, id);
        })
      }
    });
  }, []);

  useEffect(() => {
    if(cData.getCategories) {
      cData.getCategories(selectedIds);
    }
  }, [selectedIds])

  const categoryWriting = (data) => {
    setNewCategory(data.target.value);
  }

  const addCategory = () => {
    categoryService.create({label: newCategory}).then((res) => {
      setNewCategory('');
      categories.push(res.getData());
      setCategories([...categories]);
    });
  }

  const selectCategory = (e, id) => {
    if(selectedIds[id]) {
      delete selectedIds[id];
    } else {
      selectedIds[id] = true;
    }
    setsSelectedIds({...selectedIds});
  }

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Categories</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="px-3 pb-2">
            {
              categories.map((category, index) => (
                <FormCheckbox onChange={(e) => selectCategory(e, category._id)} 
                  key={category._id} 
                  className="mb-1" 
                  value={category._id || ''} 
                  checked={selectedIds[category._id]}>
                    { category.label }
                  </FormCheckbox>
              ))
            }
          </ListGroupItem>
          <ListGroupItem className="d-flex px-3">
            <InputGroup className="ml-auto">
              <FormInput value={newCategory || ''} onChange={(e) => categoryWriting(e)} placeholder="New category" />
              <InputGroupAddon type="append">
                <Button disabled={!newCategory} onClick={() => addCategory()} theme="white" className="px-2">
                  <i className="material-icons">add</i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  )
}

SidebarCategories.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarCategories.defaultProps = {
  title: "Categories"
};

export default SidebarCategories;
