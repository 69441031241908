import { CoreBaseModel } from '../Core/Models/Base.model';


export class TextModel extends CoreBaseModel {


    static getResourceName() {
        return 'texts';
    }

    getCreationDate() {
        return new Date(this.createdAt).toDateString();
    }

}