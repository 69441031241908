
import { CoreBaseService } from '../Core/Services/Base.service'; 
import { ProjectModel } from '../models/ProjectModel';


export class ProjectsService extends CoreBaseService {

  constructor() {
      super(ProjectModel);
  }

  uploadProject(id = null, fileData) {
    return this.sub(`upload-project/${id}`).create(fileData);
  }

  deleteProjectPack(projectId, packId) {
    return this.sub(`delete-project-pack/${projectId}/${packId}`).delete();
  }

}