import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CalculatorBranch from "../components/branches/Calculator/CalculatorBranch";
import { Card, CardBody, Form, FormInput, FormTextarea, FormFeedback, Button } from "shards-react";
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Branches(props) {
  
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={'Branches'} subtitle="Dashboard" className="text-sm-left" />
      </Row>
      {
        <Card small className="mb-3">
          <CardBody>
            <Row>
              {/* Editor */}
              <Col lg="12" md="12">
                <Tabs>
                  <TabList>
                    <Tab>Calculator</Tab>
                  </TabList>
                  <TabPanel>
                    <div>
                      <CalculatorBranch></CalculatorBranch>
                    </div>
                  </TabPanel>
                </Tabs>
              </Col>
            </Row>
          </CardBody>
        </Card>
      }
    </Container>
  )
};

export default Branches;
