import { CoreBaseModel } from '../../Core/Models/Base.model';


export class CalculatorModel extends CoreBaseModel {

    /**
     * Service resource name (api endpoint)
     */
    static getResourceName() {
      return 'calculator';
    }

    getDateString() {
      return new Date(this.createdAt).toDateString();
    }

}