import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import TextEditorComponent from "../components/texts/TextEditor";
import { TextsService } from "../services/TextsService";
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


function Texts(props) {

  const textsService = new TextsService();
  const [ formData, setFormData ] = useState({});
  const postIdToModify = props.match.params.id;
  const [ loading, setLoading ] = useState(true);
  const [ operationStatus, setOperationStats ] = useState(null); 
  const [ errors, setErrors ] = useState(null); 
  const { addToast } = useToasts();

  useEffect(() => {
    if(postIdToModify) {
      formData._id = postIdToModify;
      textsService.get(postIdToModify).then(res => {
        Object.assign(formData, res.getData())
        setFormData(res.getData());
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const getFormData = (data) => {
    setFormData(data);
  }

  const save = () => {
    setErrors(null);
    (!formData._id ? textsService.create(formData) : textsService.update(formData, formData._id))
      .then((res) => {
        if(res.hasErrors()) {
          addToast('An error has occurred', { appearance: 'error' });
          setErrors(res.getErrors());
          setOperationStats('error');
        } else {
          addToast('Saved successfully', { appearance: 'success' });
          if(!formData._id) {
            formData._id = res.getData()._id;
          }
          setOperationStats('success');
        }
      }).catch(e => {
        addToast('An error has occurred', { appearance: 'error' });
        setErrors(e);
        setOperationStats('error');
      })
  }

  
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={!postIdToModify ? 'Add new text' : 'Edit text'} subtitle="Projects" className="text-sm-left" />
        {
          operationStatus === 'success' ? 
            <div className="bg-success w-100 mt-2 text-white text-center rounded p-3">Success</div>
          : operationStatus === 'error' ? 
              <div className="bg-danger w-100 mt-2 text-white text-center rounded p-3">An error has occurred.</div>
          : null
        }
      </Row>
      {
        !loading ? 
          <Row>
            {/* Editor */}
            <Col lg="12" md="12">
              <TextEditorComponent 
                errors={errors}
                onSave={(e) => save(e)}
                defaultData={formData} 
                getData={(data) => getFormData(data)}
              />
            </Col>
          </Row>
        : null
      }
    </Container>
  )
};

export default Texts;
