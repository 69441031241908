
import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, FormCheckbox } from "shards-react";
import 'react-confirm-alert/src/react-confirm-alert.css';


function GenericPostConfigurationSidebar(props) {

  const cData = {
    defaultData: props.defaultData || {featured: false},
    onFeaturedChange: props.onFeaturedChange
  }

  console.log(cData.defaultData);

  const [ featured, setFeatured ] = useState(cData.defaultData.featured);

  useEffect(() => {
    if(cData.onFeaturedChange) {
      cData.onFeaturedChange(featured);
    }
  }, [featured])

  const featuredChange = (e) => {
    setFeatured(!featured);
  }

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Configuration</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="px-2 pt-1 pb-1">
            <FormCheckbox 
              onChange={(e) => featuredChange(e, 'featured')} 
              className="mb-1"
              checked={featured ? featured : ''}
            >
              Featured
            </FormCheckbox>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
}


export default GenericPostConfigurationSidebar;
