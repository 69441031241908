import { CoreBaseModel } from '../Core/Models/Base.model';

/**
 * Example model class
 */
export class PostModel extends CoreBaseModel {

    /**
     * Service resource name (api endpoint)
     */
    static getResourceName() {
        return 'posts';
    }

    getDateString() {
        return new Date(this.createdAt).toDateString();
    }

    isDraft() {
        if(this.status === 2) {
            return true;
        } else {
            return false;
        }
    }

    getCategoriesString() {
        return this.categories ? this.categories.map(category => category.label).toString(',') : '';
    }

}