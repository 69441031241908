import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
import EmptyLayout  from "./layouts/EmptyLayout";

// Route Views
import BlogOverview from "./views/BlogOverview";
import LoginPage from "./views/LoginPage";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import ProjectsList from "./views/ProjectsList";
import ProjectDetail from "./views/ProjectDetail";
import Resources from "./views/Resources";
import Texts from "./views/Texts";
import TextDetail from "./views/TextDetail";
import Branches from "./views/Branches";


export default {
  private: [
    {
      path: "/",
      exact: true,
      layout: DefaultLayout,
      component: () => <Redirect to="/blog-overview" />
    },
    {
      path: "/blog-overview",
      layout: DefaultLayout,
      component: BlogOverview
    },
    {
      path: "/user-profile-lite",
      layout: DefaultLayout,
      component: UserProfileLite
    },
    {
      path: "/add-new-post/:id?",
      layout: DefaultLayout,
      component: AddNewPost
    },
    {
      path: "/errors",
      layout: DefaultLayout,
      component: Errors
    },
    {
      path: "/components-overview",
      layout: DefaultLayout,
      component: ComponentsOverview
    },
    {
      path: "/tables",
      layout: DefaultLayout,
      component: Tables
    },
    {
      path: "/blog-posts",
      layout: DefaultLayout,
      component: BlogPosts
    },
    {
      path: "/projects",
      layout: DefaultLayout,
      component: ProjectsList
    },
    {
      path: "/project/:id?",
      layout: DefaultLayout,
      component: ProjectDetail
    },
    {
      path: "/resources",
      layout: DefaultLayout,
      component: Resources
    },{
      path: "/texts",
      layout: DefaultLayout,
      component: Texts
    },{
      path: "/text/:id?",
      layout: DefaultLayout,
      component: TextDetail
    },
    {
      path: "/branches",
      layout: DefaultLayout,
      component: Branches
    }
  ],
  public: [
    {
      path: "/login",
      exact: true,
      layout: EmptyLayout,
      component: LoginPage
    }
  ]
};
