import { CoreBaseModel } from '../Core/Models/Base.model';

/**
 * Example model class
 */
export class UserModel extends CoreBaseModel {

    /**
     * Service resource name (api endpoint)
     */
    static getResourceName() {
        return 'auth';
    }

}