import { CoreBaseService } from '../Core/Services/Base.service'; 
import { ResourcesModel } from '../models/ResourcesModel';


export class ResourcesService extends CoreBaseService  {

  constructor() {
    super(ResourcesModel);
  }

}

