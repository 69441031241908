import { CoreBaseModel } from '../Core/Models/Base.model';

/**
 * Example model class
 */
export class ProjectModel extends CoreBaseModel {

    /**
     * Service resource name (api endpoint)
     */
    static getResourceName() {
        return 'projects';
    }

    getDateString() {
        return new Date(this.createdAt).toDateString();
    }

}