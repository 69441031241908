/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Button } from "shards-react";
import { ProjectsService } from "../../services/ProjectsService";
import { Environment } from '../../Setup'; 
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useToasts } from 'react-toast-notifications';

// TODO FIXA RESPONSE MODEL
// TODO QUILL-EDITOR NON CARICA IMMAGINI O MODIFICA HTML


function SidebarProjectActions(props) {

  const cData = {
    defaultData: props.defaultData,
    project: props.project
  }

  const [ file, setFile ] = useState(null);
  const [ fileTree, setFileTree ] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    if(cData.project) {
      setFileTree(cData.project.files);
    }
  }, [])

  const onFileChange = (event) => {
    setFile({ selectedFile: event.target.files[0] });
  }

  const fileUpload = () => {
    const projectService = new ProjectsService();
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "zip",
      file.selectedFile,
      file.selectedFile.name
    );  
    // Request made to the backend api
    // Send formData object
    projectService.uploadProject(cData.defaultData._id, formData)
    .then((res) => {
      console.log(res.getData().data);
      if(!res.getData().errors) {
        let list = [];
        if(fileTree) {
          list = [...fileTree];
        }
        list.push(res.getData());
        console.log(list);
        setFileTree(list);
        addToast(res.getData().message, { appearance: 'success' });
      } else {
        addToast(res.getData().message, { appearance: 'error' });
      }
    });
  };

  const deleteProjectPack = (e, packid = null) => {
    e.preventDefault();
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to delete this packet?',
      buttons: [{
          label: 'Yes',
          onClick: () => {
            const projectService = new ProjectsService();
            projectService.deleteProjectPack(cData.defaultData._id, packid)
              .then((res) => {
                setFileTree(fileTree.filter(function( obj ) {
                  return obj._id !== res.getData()._id;
                }));
            }).catch(e => {
              console.log(e);
            });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{props.title}</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          { fileTree ?
              fileTree.length ?
                <ListGroupItem className="px-2 pt-1 pb-1">
                  { 
                    fileTree.map((file, index) => {
                      return (
                      <div key={index}>
                        <a href="#" className="float-right delete-button as-link" onClick={(e) => deleteProjectPack(e, file._id)}>Delete</a>
                        <h6 className={`mb-0 ${index > 0 ? 'mt-3' : ''}`}>{file.name}</h6>
                        <ul>
                        {
                          file.files.map((folder, indexB) => {
                            return (
                              <li key={`${index} + ${index}`}>
                                  <a 
                                    href={`${Environment.ENDPOINT}/${file.address.replace(/public\//g, "")}/${folder}`} 
                                    rel="noopener noreferrer"
                                    target="_blank">{ folder }
                                  </a>
                              </li>
                            )
                          })
                        }
                        </ul>
                      </div>)
                    })
                  }
                </ListGroupItem>
              : null
            : null
          }
          <ListGroupItem className="px-3 text-center border-0">
            {
              cData.project._id ? 
                <div className="custom-file mb-3">
                  <input accept=".zip,.rar,.7zip" type="file" onChange={onFileChange} className="custom-file-input" id="customFile2" />
                  <label className="custom-file-label" htmlFor="customFile2">
                    Choose file...
                  </label>
                </div>
              : <div className="mb-0 alert alert-primary fade show" role="alert"><i className="fa fa-info mx-2"></i>Create a project to upload the packets</div>
            }
            {
              file ?
                <Button onClick={() => fileUpload()} theme="accent" size="sm" className="mt-3">
                  <i className="material-icons">file_copy</i> Select and upload
                </Button>
              : null
            }
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
}

SidebarProjectActions.propTypes = {
  title: PropTypes.string
};

SidebarProjectActions.defaultProps = {
  title: "Files and folders"
};

export default SidebarProjectActions;
