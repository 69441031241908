
import { CoreBaseService } from '../Core/Services/Base.service'; 
import { CategoryModel } from '../models/CategoryModel';


export class CategoryService extends CoreBaseService {

  constructor() {
      super(CategoryModel);
  }

}