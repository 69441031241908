import React from "react";
import { BrowserRouter as Router, HashRouter, Route } from "react-router-dom";
import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/global.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import { Redirect } from 'react-router';
import { AuthService } from './services/AuthService';
import axios from 'axios';
import { ToastProvider } from 'react-toast-notifications';

/**
 * Setup Axios intercepto for JWToken
 */
axios.interceptors.request.use(function (config) {
  const token = (new AuthService()).hasAuthToken();
  config.headers["x-auth-token"] =  token;
  return config;
});

export default () => (
  <ToastProvider placement={'bottom-right'} autoDismiss={true} autoDismissTimeout={'5000'}>
    <HashRouter basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        {routes.public.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
        {routes.private.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                   AuthService.isLogged() ? 
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  : <Redirect to={{pathname: '/login'}} />
                );
              })}
            />
          );
        })}
      </div>
    </HashRouter>
    </ToastProvider>
);
