
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from "shards-react";
import { ResourcesService } from "../services/ResourcesService";
import PageTitle from "../components/common/PageTitle";
import { useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert'; 


function Resources() {

  const [ resources, setResources ] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    const resourcesService = new ResourcesService();
    resourcesService.get().then(res => {
      setResources(res.getData());
    });
  }, []);

  const onFileChange = (event) => {
    const file = event.target.files[0];
    const resourcesService = new ResourcesService();
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      file,
      file.name
    );  
    resourcesService.create(formData).then((res) => {
      if(!res.getData().errors) {
        resources.push(res.getData());
        setResources(resources.map((x) => x));
        addToast(res.getData().message, { appearance: 'success' });
      } else {
        addToast(res.getData().message, { appearance: 'error' });
      }
    });
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    addToast('Image url copied to clipboard', { appearance: 'success' });
  }

  const deleteResource = (resource) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to delete this resource?',
      buttons: [{
          label: 'Yes',
          onClick: () => {
            const resourcesService = new ResourcesService();
            resourcesService.delete(resource).then((res) => {
              if(!res.getData().errors) {
                //resources.push(res.getData().data);
                setResources(resources.filter((x) => x._id !== resource._id));
                addToast(res.getData().message, { appearance: 'success' });
              } else {
                addToast(res.getData().message, { appearance: 'error' });
              }
            });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Resources" subtitle="Resources" className="text-sm-left" />
      </Row>
      {
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Resources list</h6>
                  <div className="float-right w-25">
                    <div className="custom-file mb-3">
                      <input accept=".jpg, .bmp, .pdf, .jpeg, .tiff, .png, .gif" type="file" onChange={(event) => onFileChange(event)} className="custom-file-input" id="customFile2" />
                      <label className="custom-file-label" htmlFor="customFile2">
                        Choose file...
                      </label>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Name
                        </th>
                        <th scope="col" className="border-0">
                          Size
                        </th>
                        <th scope="col" className="border-0">
                          Mime
                        </th>
                        <th scope="col" className="border-0">
                          Encoding
                        </th>
                        <th scope="col" className="border-0"></th>
                        <th scope="col" className="border-0">
                          Uploaded
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        resources.map(resource => {
                          return ( 
                            <tr key={resource._id}>
                              <td><a rel="noopener noreferrer" target="_blank" href={resource.getFilePath()}>{ resource.original_name }</a></td>
                              <td>{ resource.size }</td>
                              <td>{ resource.mimetype }</td>
                              <td>{ resource.encoding }</td>
                              <td>
                                <Button onClick={() => copyToClipboard(resource.getFilePath())}>Copy url</Button>
                                <Button className="btn-danger" onClick={() => deleteResource(resource)}>Delete</Button>
                              </td>
                              <td>{ resource.getDateString() }</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
      }
    </Container>
  );
};

export default Resources;
