import { CoreBaseModel } from '../Core/Models/Base.model';


export class CategoryModel extends CoreBaseModel {

    /**
     * Service resource name (api endpoint)
     */
    static getResourceName() {
        return 'categories';
    }

}