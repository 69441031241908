import { CoreBaseService } from '../Core/Services/Base.service'; 
import { AuthModel } from '../models/AuthModel';

/**
 * Auth Service
 */
export class AuthService extends CoreBaseService  {

  static isLogged() {
    const self = new AuthService();
    return self.hasAuthToken();
  }

  constructor() {
    super(AuthModel);
  }

  logout() {
    window.localStorage.removeItem('auth');
    return this.sub('user/logout').get();
  }

  saveAuthToken(JWToken) {
    window.localStorage.setItem('auth', JWToken);
    return this;
  }

  hasAuthToken() {
    return window.localStorage.getItem('auth');
  }

}

