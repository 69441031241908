
import React, { useEffect, useState } from "react";
import { Row, Container, Col, Form, Button, FormInput, FormSelect, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from "shards-react";
import "../../src/assets/login.css";
import { UserService } from "../services/UserService";
import axios from 'axios';
import { Redirect } from 'react-router'
import { AuthService } from "../services/AuthService";

function LoginPage() {

  const authService = new AuthService();
  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState();
  const [ redirect, setRedirect ] = useState(false);

  const userService = new UserService();

  useEffect(() => {
  }, []);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const confirm = (e) => {
    userService.login({
      email,
      password
    }).then((res) => {
      if(res.getData().token) {
        const JWToken = res.getData().token;
        setRedirect(true);
        authService.saveAuthToken(JWToken)
        axios.interceptors.request.use(function (config) {
          const token = authService.hasAuthToken();
          config.headers["x-auth-token"] =  token;
          return config;
        });
      }
    });
  }

  return (
    <div>
      <div className="login-form-wrapper">
        {
          redirect ?
            <Redirect to="/blog-posts" push />
          : null
        }
        <Form>
          <h1>Login</h1>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>@</InputGroupText>
              </InputGroupAddon>
              <FormInput onChange={(e) => onEmailChange(e)} placeholder="Email" />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>P</InputGroupText>
              </InputGroupAddon>
              <FormInput type="password" onChange={(e) => onPasswordChange(e)} placeholder="Password" />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Button onClick={() => confirm()} theme="primary">Confirm</Button>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;


