
import { CoreBaseService } from '../Core/Services/Base.service'; 
import { TextModel } from '../models/TextModel';


export class TextsService extends CoreBaseService {

  constructor() {
      super(TextModel);
  }

}