import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Form, FormInput, FormTextarea, FormFeedback, Button } from "shards-react";
import { CoreServiceErrorModel } from "../../Core/Models/ServiceError.model";
import ReactQuill from "react-quill";
import '../../../node_modules/react-quill/dist/quill.snow.css';

// ! https://jpuri.github.io/react-draft-wysiwyg/#/docs

function EditorComponent(props) {

  const cData = {
    defaultData: props.defaultData,
    errors: props.errors ||  new CoreServiceErrorModel(),
    save: props.onSave,
    onDelete: props.onDelete
  }
  
  const [ formData, setFormData ] = useState(props.defaultData);


  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    props.getData(formData);
  }, [formData]);

  const titleChange = (event) => {
    formData.title = event.target.value;
    setFormData({...formData});
  }

  const previewChange = (event) => {
    formData.description = event.target.value;
    setFormData({...formData});
  }

  const coverChange = (event) => {
    formData.coverurl = event.target.value;
    setFormData({...formData});
  }

  const contentChange = (value) => {
    formData.content = value;
    setFormData({...formData});
  }

  const savePressed = () => {
    if(cData.save) {
      cData.save();
    }
  }

  const deletePressed = () => {
    if(cData.onDelete) {
      cData.onDelete();
    }
  }

  return (
    <Card small className="mb-3">
      {
        cData.errors ? 
        <CardBody>
          <Form className="add-new-post">
            <FormInput 
              invalid={cData.errors.hasError('title')} 
              defaultValue={cData.defaultData.title} 
              onChange={(e) => titleChange(e)} 
              size="lg" 
              placeholder="Your Post Title" 
            />

            { cData.errors.hasError('title') ? <FormFeedback className="mb-3">{ cData.errors.hasError('title').message }</FormFeedback> : null }

            <FormTextarea 
              invalid={cData.errors.hasError('description')} 
              defaultValue={cData.defaultData ? cData.defaultData.description : ''} 
              onChange={(e) => previewChange(e)} 
              size="lg" 
              className="mt-1" 
              placeholder="Your post preview" 
            />
            { cData.errors.hasError('description') ? <FormFeedback className="mb-3">{ cData.errors.hasError('description').message }</FormFeedback> : null }

            <FormTextarea 
              invalid={cData.errors.hasError('coverurl')} 
              defaultValue={cData.defaultData ? cData.defaultData.coverurl : ''} 
              onChange={(e) => coverChange(e)} 
              size="lg" 
              className="mt-1" 
              placeholder="Your post cover url" 
            />
            { cData.errors.hasError('coverurl') ? <FormFeedback className="mb-3">{ cData.errors.hasError('coverurl').message }</FormFeedback> : null }

            <ReactQuill
              onChange={contentChange}
              className="mt-3"
              value={formData ? formData.content ? formData.content : '' : ''}
              modules={{
                toolbar: {
                  container: [
                      [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
                      [{ size: [] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link', 'image', 'video'],
                      ['clean'],
                      ['code-block']
                  ]
                }
              }}
            />

            { cData.errors.hasError('content') ? <FormFeedback className="mb-3">{ cData.errors.hasError('content').message }</FormFeedback> : null }

          </Form>
          {
            cData.save ? 
              <div className="float-right">
                <Button onClick={() => savePressed()} theme="accent" size="sm" className="mt-3">
                    <i className="material-icons">file_copy</i> Save
                </Button>
              </div>
            : null
          }
          {
            cData.onDelete ? 
              <div className="float-left">
                <Button onClick={() => deletePressed()} size="sm" className="mt-3 btn btn-danger">
                    <i className="material-icons">file_copy</i> Delete
                </Button>
              </div>
            : null
          }
        </CardBody>
        : null
      }
    </Card>
  );
}

EditorComponent.defaultProps = {
  errors: new CoreServiceErrorModel()
};

export default EditorComponent;
