import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import EditorComponent from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";
import { PostsService } from "../services/PostsService";
import { useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Redirect } from 'react-router';
import GenericPostConfigurationSidebar from "../components/common/GenericPostConfigurationSidebar";


function AddNewPost(props) {

  const postsService = new PostsService();
  const [ formData, setFormData ] = useState({});
  const [ categories, setCategories ] = useState([]);
  const postIdToModify = props.match.params.id;
  const [ loading, setLoading ] = useState(true);
  const [ operationStatus, setOperationStats ] = useState(null); 
  const [ errors, setErrors ] = useState(null); 
  const { addToast } = useToasts();
  const [ deleted, setDeleted ] = useState(false);

  useEffect(() => {
    if(postIdToModify) {
      postsService.getPost(postIdToModify).then(res => {
        formData.title = res.getData().title;
        formData.slug = res.getData().slug;
        formData.content = res.getData().content;
        formData.status = res.getData().status;
        formData.coverurl = res.getData().coverurl;
        formData.featured = res.getData().featured;
        formData.categories = res.getData().categories;
        formData.description = res.getData().description;
        formData._id = res.getData()._id;
        setLoading(false);
        setFormData(formData);
      });
    } else {
      setLoading(false);
    }
  }, []);
  
  const getCategories = (data) => {
    setCategories(data);
  }

  const getFormData = (data) => {
    setFormData(data);
  }

  const savePost = (draft = false) => {
    setErrors(null);
    formData.categories = Object.keys(categories);
    if(formData.status === 2 && !draft) {
      confirmAlert({
        title: 'Confirm publishing',
        message: 'The article is a draft. Are you sure you want to publish it?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => confirmSave(draft)
          },
          {
            label: 'No'
          }
        ]
      });
    } else {
      confirmSave(draft)
    }
  }

  const onDelete = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'The article will be delete permanently',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            postsService.delete(formData._id)
            .then(e => {
              addToast('Deleted successfully', { appearance: 'success' });
              setDeleted(true);
            })
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const featuredChange = (featured) => {
    formData.featured = featured;
    setFormData(formData);
  }

  const confirmSave = (draft) => {
    formData.status = draft ? 2 : 1;
    (!formData._id ? postsService.create(formData) : postsService.update(formData, formData._id))
      .then((res) => {
        if(res.hasErrors()) {
          addToast('An error has occurred', { appearance: 'error' });
          setErrors(res.getErrors());
          setOperationStats('error');
        } else {
          addToast('Saved successfully', { appearance: 'success' });
          if(!formData._id) {
            formData._id = res.getData()._id;
          }
          setOperationStats('success');
        }
      }).catch(e => {
        addToast('An error has occurred', { appearance: 'error' });
        setErrors(e);
        setOperationStats('error');
      })
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {
        deleted ? 
          <Redirect to={{pathname: '/blog-posts'}} />
        : null
      }
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={!postIdToModify ? 'Add New Post' : 'Edit post'} subtitle="Blog Posts" className="text-sm-left" />
        {
          operationStatus === 'success' ? 
            <div className="bg-success w-100 mt-2 text-white text-center rounded p-3">Success</div>
          : operationStatus === 'error' ? 
              <div className="bg-danger w-100 mt-2 text-white text-center rounded p-3">An error has occurred.</div>
          : null
        }
      </Row>
      {
        !loading ? 
          <Row>
            {/* Editor */}
            <Col lg="9" md="12">
              <EditorComponent
                errors={errors}
                defaultData={formData}
                getData={(data) => getFormData(data)}
                onDelete={(e) => onDelete(e)}
              />
            </Col>
            {/* Sidebar Widgets */}
            <Col lg="3" md="12">
              <GenericPostConfigurationSidebar onFeaturedChange={(featured) => featuredChange(featured)} defaultData={formData} />
              <SidebarActions defaultData={formData} publish={() => savePost()} saveDraft={() => savePost(true)} />
              <SidebarCategories preselectedCategories={formData.categories ? formData.categories.map(category => category._id) : []} getCategories={(data) => getCategories(data)}/>
            </Col>
          </Row>
        : null
      }
    </Container>
  )
};

export default AddNewPost;
