import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import EditorComponent from "../components/add-new-post/Editor";
import SidebarProjectActions from "../components/project-detail/SidebarProjectActions";
import GenericPostConfigurationSidebar from "../components/common/GenericPostConfigurationSidebar";
import { ProjectsService } from "../services/ProjectsService";
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Redirect } from 'react-router';
import { confirmAlert } from 'react-confirm-alert'; 


function ProjectDetail(props) {

  const projectsService = new ProjectsService();
  const [ formData, setFormData ] = useState({});
  const postIdToModify = props.match.params.id;
  const [ loading, setLoading ] = useState(true);
  const [ operationStatus, setOperationStats ] = useState(null); 
  const [ errors, setErrors ] = useState(null); 
  const { addToast } = useToasts();
  const [ deleted, setDeleted ] = useState(false);

  useEffect(() => {
    if(postIdToModify) {
      formData._id = postIdToModify;
      projectsService.get(postIdToModify).then(res => {
        Object.assign(formData, res.getData())
        setFormData(res.getData());
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const getFormData = (data) => {
    setFormData(data);
  }

  const featuredChange = (featured) => {
    formData.featured = featured;
    setFormData(formData);
  }

  const save = () => {
    setErrors(null);
    (!formData._id ? projectsService.create(formData) : projectsService.update(formData, formData._id))
    .then((res) => {
      if(res.hasErrors()) {
        addToast('An error has occurred', { appearance: 'error' });
        setErrors(res.getErrors());
        setOperationStats('error');
      } else {
        addToast('Saved successfully', { appearance: 'success' });
        if(!formData._id) {
          formData._id = res.getData()._id;
        }
        setOperationStats('success');
      }
    }).catch(e => {
      addToast('An error has occurred', { appearance: 'error' });
      setErrors(e);
      setOperationStats('error');
    })
  }

  const onDelete = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'The project will be permanently delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            projectsService.delete(formData._id)
            .then(e => {
              addToast('Deleted successfully', { appearance: 'success' });
              setDeleted(true);
            })
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {
        deleted ? 
          <Redirect to={{pathname: '/projects'}} />
        : null
      }
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={!postIdToModify ? 'Add new project' : 'Edit project'} subtitle="Projects" className="text-sm-left" />
        {
          operationStatus === 'success' ? 
            <div className="bg-success w-100 mt-2 text-white text-center rounded p-3">Success</div>
          : operationStatus === 'error' ? 
              <div className="bg-danger w-100 mt-2 text-white text-center rounded p-3">An error has occurred.</div>
          : null
        }
      </Row>
      {
        !loading ? 
          <Row>
            {/* Editor */}
            <Col lg="9" md="12">
              <EditorComponent 
                errors={errors}
                onSave={(e) => save(e)}
                defaultData={formData} 
                getData={(data) => getFormData(data)}
                onDelete={(e) => onDelete(e)}
              />
            </Col>
            {/* Sidebar Widgets */}
            <Col lg="3" md="12">
              <GenericPostConfigurationSidebar onFeaturedChange={(featured) => featuredChange(featured)} defaultData={formData} />
              <SidebarProjectActions project={formData} defaultData={formData} />
            </Col>
          </Row>
        : null
      }
    </Container>
  )
};

export default ProjectDetail;
