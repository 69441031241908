
import { CoreBaseService } from '../../Core/Services/Base.service'; 
import { CalculatorModel } from '../../models/branches/CalculatorModel';

export class CalculatorService extends CoreBaseService {

  constructor() {
      super(CalculatorModel);
  }

  addThought(body) {
    return this.sub('add-thought').create(body);
  }

}