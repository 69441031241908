
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from "shards-react";
import { TextsService } from "../services/TextsService";
import PageTitle from "../components/common/PageTitle";
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';


import axios from 'axios';


function Texts() {

  const [ texts, setTexts ] = useState([]);

  useEffect(() => {
    const textsService = new TextsService();
    textsService.get().then(res => {
      setTexts(res.getData());
    });
  }, []);


  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Texts" subtitle="Texts" className="text-sm-left" />
      </Row>
      {
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Texts list</h6>
                <div className="float-right w-25 text-right">
                  <Link className="" to={`/text`}>
                    <Button className="btn-success">New text</Button>
                  </Link>
                </div>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0 w-50">
                        Title
                      </th>
                      <th scope="col" className="border-0">
                        Slug
                      </th>
                      <th scope="col" className="border-0">
                        Created
                      </th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      texts.map(text => {
                        return ( 
                          <tr key={text._id}>
                            <td>
                              <Link className="" to={`/text/${text._id}`}>
                                { text.title ? text.title : '--'}
                              </Link>
                            </td>
                            <td>{ text.slug }</td>
                            <td>{ text.getCreationDate() }</td>
                            <td className="text-right">
                              <Link className="" to={`/text/${text._id}`}>
                                <Button className="btn-info">Modify</Button>
                              </Link>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      }
    </Container>
  );
};

export default Texts;
